import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import Salesforce from "../services/Salesforce"
import { STAGE_COMPLETE_POURCENT, STAGE_INSCRIPTION, STAGE_INSCRIPTION_POURCENT, STAGE_INSCRIT, STAGE_INSCRIT_POURCENT } from "../services/StageNameOpportunity"



const computeStatusPayment = (opportunities) => {
  const opportunitiesPaymentKo = opportunities.find(el => el.payment_status_candidature__c === 'KO')
  const opportunitiesPaymentOk = opportunities.find(el => el.payment_status_candidature__c === 'OK')
  const opportunitiesPaymentReached = opportunities.find(el => el.payment_status_candidature__c === 'reached_bank_website')

  if (opportunitiesPaymentReached !== undefined) {
    return 'reached_bank_website'
  }

  if (opportunitiesPaymentKo !== undefined) {
    return 'KO'
  }

  if (opportunitiesPaymentOk !== undefined) {
    return 'OK'
  }
}


const computeOpportunities = (opportunities, newOpportunities) => {
  if (newOpportunities !== undefined && newOpportunities[0].Id !== undefined) {
    const index = opportunities.findIndex(el => el.Id === newOpportunities[0].Id)
    if (index !== -1) {
      opportunities[index] = newOpportunities
      return opportunities
    } else {
      return opportunities.concat(newOpportunities)
    }
  }
}


const computeOpportunity = (newOpportunity, sessions) => {
  let state = {}

  state.opportunity = newOpportunity

  const session = sessions.find(el => el.Id === state.opportunity.Session__c)

  if (session) {
    state.session = session
  }

  return state
}


const computeOneOppyCompleted = (opportunities) => {
  const found = opportunities.find(el => el.Probability >= STAGE_COMPLETE_POURCENT)

  return found !== undefined
}


const computeOneOppyRegisteredFinished = (opportunities) => {
  const found = opportunities.find(el => el.StageName === STAGE_INSCRIPTION && el.RegistrationValidFormDate__c !== null)

  return found !== undefined
}


const computeOneOppyToBeRegistered = (opportunities) => {
  const found = opportunities.find(el => el.StageName === STAGE_INSCRIPTION)

  return found !== undefined
}


const computeIsRegistered = (opportunities) => {
  const found = opportunities.find(el => el.StageName === STAGE_INSCRIT)

  return found !== undefined
}


export const SalesforceTest = createAsyncThunk(
  'truc/tructest',
  async (test) => {
    const response = await Salesforce.test(test)
    return response
  }
)

export const SalesforceGetData = createAsyncThunk(
  'salesforce/getData',
  async () => {
    const response = await Salesforce.getSalesforceData()
    return response
  }
)

export const SalesforceLoadAccountData = createAsyncThunk(
  'salesforce/getAccountData',
  async (data) => {
    const { account, campaigns } = data
    const response = await Salesforce.getAccountData(account, campaigns)

    return response
  }
)

export const SalesforceUpdateStageStop = createAsyncThunk(
  'salesforce/updateStageStop',
  async (obj) => {
    const response = await Salesforce.updateStageStop(obj.accountId, obj.newStep)
    return response
  }
)

export const SalesforceGoToPayment = createAsyncThunk(
  'salesforce/goToPayment',
  async (obj) => {
    const response = await Salesforce.goToPayment(obj.opportunityId, obj.type)
    return response
  }
)

export const salesforceSlice = createSlice({
  name: 'salesforce',
  initialState: {
    academicInformation: [],
    academicLevels: [],
    account: {},
    accounts: [],
    applicationPrice: 0,
    bacHonour: [],
    business: process.env.REACT_APP_SCHOOL_TYPE === 'Business',
    campaignId: '',
    campaignMember: {},
    campaigns: [],
    countries: [],
    csp: [],
    degreeOfRelationship: [],
    division: process.env.REACT_APP_SCHOOL,
    documents: [],
    docusignStatus: null,
    firstInformationSource: [],
    hasLoginOnce: false,
    hasOneOppyCompleted: false,
    ionisConnectId: '',
    hasAccounts: false,
    hasOneOppyRegisteredFinished: false,
    hasOneOppyToBeRegistered: false,
    ionisSchools: [],
    inge: process.env.REACT_APP_SCHOOL_TYPE === 'Inge',
    isLogged: false,
    isLogin: true,
    isRegistered: false,
    isSalesforceDataRetrieve: false,
    language: 'fr',
    languageLevels: [],
    languages: [],
    lycees: [],
    modifyStep: null,
    nationalities: [],
    opportunities: [],
    opportunity: {},
    paymentApplicationStripe: {
      amount: 0,
      clientSecret: '',
      payment: false
    },
    paymentRegistrationStripe: {
      amount: 0,
      clientSecret: '',
      payment: false
    },
    paymentType: 'JPC',
    recap: {
      hasCheckedParents: true,
      hasModifiedParents: false,
    },
    recordTypeAccount: '',
    recordTypeOpportunity: '',
    returnPages: {
      confirmation: '/confirmation-candidature',
      confirmation_inscription: '/confirmation-inscription',
      payment_ko: '',
      payment_ok: ''
    },
    sectionOfBac: [],
    sections: [],
    session: {},
    sessions: [],
    school: process.env.REACT_APP_SCHOOL,
    specialities: [],
    status: 'loading',
    statusPayment: '',
    supportingDocument: [],
    workExperience: []
  },
  reducers: {
    getStatusPayment: (state, action) => {
      state.statusPayment = computeStatusPayment(action.payload)
    },
    /**
     *
     */
    hasLoginOnce: (state, action) => {
      state.hasLoginOnce = action.payload !== ''
    },
    hasAccounts: (state, action) => {
      state.hasAccounts = !state.hasAccounts
    },
    hasOneOppyCompleted: (state, action) => {
      state.hasOneOppyCompleted = computeOneOppyCompleted(action.payload)
    },
    hasOneOppyRegisteredFinished: (state, action) => {
      state.hasOneOppyRegisteredFinished = computeOneOppyRegisteredFinished(action.payload)
    },
    hasOneOppyToBeRegistered: (state, action) => {
      state.hasOneOppyToBeRegistered = computeOneOppyToBeRegistered(action.payload)
    },
    isRegistered: (state, action) => {
      state.isRegistered = computeIsRegistered(action.payload)
    },
    login: state => {
      state.value = 'coucou'
      // state.value = Salesforce.login()
    },
    isLogged: state => {
      state.isLogged = !state.isLogged
    },
    removeOpportunities: (state, action) => {
      state.opportunities = []
    },
    updateAcademicInformation: (state, action) => {
      state.academicInformation = action.payload
    },
    updateAccount: (state, action) => {
      state.account = action.payload
    },
    updateCampaignId: (state, action) => {
      state.campaignId = action.payload
    },
    updateAccounts: (state, action) => {
      state.accounts = action.payload
    },
    updateCampaignMember: (state, action) => {
      state.campaignMember = action.payload
    },
    updateIonisConnectId: (state, action) => {
      state.ionisConnectId = action.payload
    },
    updateDocusignStatus: (state, action) => {
      state.docusignStatus = action.payload
    },
    updateLanguage: (state, action) => {
      state.language = action.payload
    },
    updateIonisConnect: (state, action) => {
      state.isLogin = action.payload
    },
    updateModifyStep: (state, action) => {
      state.modifyStep = action.payload
    },
    updateOpportunities: (state, action) => {
      state.opportunities = action.payload
    },
    updateOpportunity: (state, action) => {
      const newOpportunity = computeOpportunity(action.payload, state.sessions)

      state.opportunity = newOpportunity.opportunity
      state.session = newOpportunity.session
    },
    updatePaymentType: (state, action) => {
      state.paymentType = action.payload
    },
    updateRecap: (state, action) => {
      state.recap = action.payload
    },
    updateStatus: (state, action) => {
      state.status = action.payload
    },
    updateStripeAmount: (state, action) => {
      if (action.payload.type === 'candidature') {
        state.paymentApplicationStripe.amount = action.payload.amount
      }
      if (action.payload.type === 'inscription') {
        state.paymentRegistrationStripe.amount = action.payload.amount
      }
      // state.stripe.payment = action.payload
    },
    updateStripeClientSecret: (state, action) => {
      if (action.payload.type === 'candidature') {
        state.paymentApplicationStripe.clientSecret = action.payload.clientSecret
      }
      if (action.payload.type === 'inscription') {
        state.paymentRegistrationStripe.clientSecret = action.payload.clientSecret
      }
      // state.stripe.clientSecret = action.payload
    },
    updateStripePayment: (state, action) => {
      if (action.payload.type === 'candidature') {
        state.paymentApplicationStripe.payment = action.payload.payment
      }
      if (action.payload.type === 'inscription') {
        state.paymentRegistrationStripe.payment = action.payload.payment
      }
      // state.stripe.payment = action.payload
    },
    updateWorkExperience: (state, action) => {
      state.workExperience = action.payload
    },
    updateSupportingDocument: (state, action) => {
      state.supportingDocument = action.payload
    },
  },
  extraReducers: {
    [SalesforceTest.fulfilled]: (state, action) => {
      state.account = action.payload
    },
    [SalesforceGetData.pending]: (state, action) => {
      state.status = 'loading'
    },
    [SalesforceGetData.fulfilled]: (state, action) => {
      state.status = 'success'
      state.academicLevels = action.payload.academicLevels
      state.applicationPrice = action.payload.price
      state.campaigns = action.payload.campaigns.campaigns
      state.bacHonour = action.payload.bacHonour
      state.campaignId = action.payload.campaignId
      state.countries = action.payload.countries
      state.csp = action.payload.csp
      state.degreeOfRelationship = action.payload.degreeOfRelationship
      state.documents = action.payload.documents
      state.firstInformationSource = action.payload.firstInformationSource
      state.ionisSchools = action.payload.ionisSchools
      state.languages = action.payload.languages
      state.languageLevels = action.payload.languageLevels
      state.lycees = action.payload.lycees
      state.nationalities = action.payload.nationalities
      state.recordTypeAccount = action.payload.recordTypeAccount
      state.recordTypeOpportunity = action.payload.recordTypeOpportunity
      state.sectionOfBac = action.payload.sectionOfBac
      state.sections = action.payload.sections
      state.sessions = action.payload.sessions
      state.specialities = action.payload.specialities
      state.isSalesforceDataRetrieve = true
    },
    [SalesforceGetData.rejected]: (state, action) => {
      state.status = 'failed'

    },
    [SalesforceLoadAccountData.pending]: (state, action) => {
      state.status = 'loading'
    },
    [SalesforceLoadAccountData.rejected]: (state, action) => {
      state.status = 'failed'
    },
    [SalesforceLoadAccountData.fulfilled]: (state, action) => {
      const { payload } = action
      const { account, campaignMember, academicInformation, workExperience, opportunities, lastConnection, paymentType } = payload

      state.account = account
      state.campaignMember = campaignMember
      state.academicInformation = academicInformation
      state.workExperience = workExperience
      state.hasLoginOnce = lastConnection !== ''
      state.ionisConnectId = account.IONISConnect__c

      if (opportunities.length > 0) {

        state.statusPayment = computeStatusPayment(opportunities)
        state.opportunities = computeOpportunities(state.opportunities, opportunities)

        if (payload.opportunity) {
          const newOpportunity = computeOpportunity(payload.opportunity, state.sessions)

          state.opportunity = newOpportunity.opportunity
          state.session = newOpportunity.session

          if (payload.paymentRegistrationStripe) {
            state.paymentRegistrationStripe = payload.paymentRegistrationStripe
          }
          state.docusignStatus = payload.docusignStatus
        } else {
          const newOpportunity = computeOpportunity(opportunities[0], state.sessions)

          state.opportunity = newOpportunity.opportunity
          state.session = newOpportunity.session
        }

        state.supportingDocument = payload.supportingDocument

        state.hasOneOppyCompleted = computeOneOppyCompleted(opportunities)
        state.hasOneOppyRegisteredFinished = computeOneOppyRegisteredFinished(opportunities)
        state.hasOneOppyToBeRegistered = computeOneOppyToBeRegistered(opportunities)
        state.isRegistered = computeIsRegistered(opportunities)

        state.paymentType = paymentType
      }

      state.isLogged = !state.isLogged
      state.status = 'success'
    },
    [SalesforceUpdateStageStop.pending]: (state, action) => {
    },
    [SalesforceUpdateStageStop.fulfilled]: (state, action) => {
      console.log('SalesforceUpdateStageStop', action.payload)
      state.account.StageStop__c = action.payload.data.StageStop__c
    },
    [SalesforceUpdateStageStop.rejected]: (state, action) => {
    },
    [SalesforceGoToPayment.pending]: (state, action) => {
    },
    [SalesforceGoToPayment.fulfilled]: (state, action) => {
      state.statusPayment = action.payload.status
    },
    [SalesforceGoToPayment.rejected]: (state, action) => {
    }
  }
})

export const { getStatusPayment, hasAccounts, hasLoginOnce, hasOneOppyCompleted, hasOneOppyRegisteredFinished, hasOneOppyToBeRegistered, isRegistered, isLogged, login, removeOpportunities, updateAcademicInformation, updateAccount, updateAccounts, updateCampaignId, updateCampaignMember, updateDocusignStatus, updateIonisConnect, updateIonisConnectId, updateLanguage, updateModifyStep, updateOpportunities, updateOpportunity, updatePaymentType, updateRecap, updateStatus, updateStripeAmount, updateStripeClientSecret, updateStripePayment, updateSupportingDocument, updateWorkExperience} = salesforceSlice.actions

export default salesforceSlice.reducer
